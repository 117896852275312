<template>
  <div>
    <v-container v-if="isProcessing" class="fill-height fullscreen-bg" fluid> 
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="6">
          <v-card class="elevation-12 pa-4 text-center">
            <v-card-title class="headline d-block" style="">{{$t('account.preparing-title')}}</v-card-title>
            <v-card-text class="">
              <p>{{$t('account.preparing-text')}}</p>
              <v-progress-circular indeterminate :size="100" :width="5" color="primary" class="mx-a"></v-progress-circular>
              <v-alert v-if="error" type="error">Error while creating account: {{error}}</v-alert>
            </v-card-text>

          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else-if="error" class="fill-height fullscreen-bg" fluid> 
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="6">
          <v-card class="elevation-12 pa-4 text-center">
            <v-card-title class="headline d-block" style="">{{$t('shared.error-message')}}</v-card-title>
            <v-card-text class="">
              {{ error }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else-if="!user" class="fill-height fullscreen-bg" fluid> 
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="6">
          <v-card class="elevation-12 pa-4 text-center">
            <v-card-title class="headline d-block" style="">{{$t('account.preparing-title')}}</v-card-title>
            <v-card-text class="">
              <v-btn @click="login">Log In using SSO</v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else-if="user" class="fill-height fullscreen-bg" fluid> 
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="6">
          <v-card class="elevation-12 pa-4 text-center">
            <v-card-title class="headline d-block" style="">{{$t('account.preparing-title')}}</v-card-title>
            <v-card-text v-if="false" class="">
              <v-btn @click="redirect">Open your profile</v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getAuth, SAMLAuthProvider, signInWithRedirect, getRedirectResult, getAdditionalUserInfo } from "firebase/auth";
import { browserPopupRedirectResolver } from "firebase/auth";
import eventService from "@/services/eventService";
import profileService from "@/services/profileService";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  data: () => ({
    isProcessing: true,
    error: null,
  }),

  async mounted() {
    const provider = await this.getSamlProvider();

    if (!provider) {
      this.error = 'SSO is not available for this event.';
      return;
    }
    console.log('/// samlprovider:', provider);

    var redirectUrl = `${process.env.VUE_APP_SSO_REDIRECT_URL}`;
    var authDomain = tenant.authDomain || process.env.VUE_APP_FIREBASE_AUTH_DOMAIN;
    if (this.isAppView) {
      // the app will listen to the special scheme and then extracts the token
      redirectUrl = `${tenant.appScheme}://auth`;
    }
    else {
      // For web we redirect to the Firebase token handler
    }

    let authUrl = `https://${authDomain}/?samlProviderId=${provider.providerId}&state=${this.$route.query.state||''}&redirect=${redirectUrl}`;
    window.location.replace(authUrl);
    return;


    // all this is now hosted on firebase hosting plan


    const auth = getAuth();

    const user = auth.currentUser;
    
    var result;
    try {
    result = await getRedirectResult(auth, browserPopupRedirectResolver);
    }
    catch (ex) {
      console.log('/// getRedirectResult err:', ex.message, ex);
      this.error = ex.message;
      return;
    }
    await this.logDebug(`authResponse: ${result != null}`); 
    console.log('/// authResponse:', result, provider);
    if (result) {
      // This is the signed-in user
      const user = result.user;
      console.log('/// user:', user);
      await this.logDebug(`user: ${user != null}, email: ${user != null && user.email}`); 
      this.$store.dispatch("setUser", user);
      this.$analytics.event('login', { method : 'SSO' });
      
      const userInfo = await getAdditionalUserInfo(result);
      const providerData = user.providerData.find(x => x.providerId == provider.providerId);
      //console.log('/// userInfo:', providerData, userInfo);
      await this.logDebug(`userInfo: ${userInfo != null}, providerData: ${providerData != null}`); 

      const model = {
        given_name: userInfo.profile.given_name,
        family_name: userInfo.profile.family_name,
        email: userInfo.profile.email,
        provider_id: providerData.uid,
      };
      // create user
      var response = (await profileService.sso(model, this.$route.query.state)).data;
      await this.logDebug(`ssoResponse: ${response != null}, status: ${response != null && response.status}`); 
      // get token
      if (response.status === 'OK') {
        var tokenResponse = (await profileService.createToken()).data;
        // update state and do redirect (for app to take over)
        var redirectUrl = `${process.env.VUE_APP_SSO_REDIRECT_URL}?token=${tokenResponse.token||''}&state=${this.$route.query.state||''}`;
        if (this.isAppView) {
          // the app will listen to the special scheme and then extracts the token
          redirectUrl = `${tenant.appScheme}://auth/?token=${tokenResponse.token||''}&state=${this.$route.query.state||''}`;
        }
        else {
          // For web we redirect to the Firebase token handler
        }
        try {
            window.location.replace(redirectUrl);
        }
        catch(ex) {
          this.error = ex.message;
          console.log('/// redirect err:', ex.message, ex);
        }
        return;
      }
      else {
        this.$helpers.toastResponse(this, response);
        this.error = response.msg;
      }
      return;
    }
    else if (user == null) {
      this.isProcessing = false;
      try {
        await this.logDebug(`calling signInWithRedirect`); 
        await signInWithRedirect(auth, provider, browserPopupRedirectResolver);
      }
      catch (ex) {
        console.log('/// signInWithRedirect err:', ex.message, ex);
        this.error = ex.message;
        return;
      }
    }
  },

  methods: {
    async logDebug(msg) {
      console.log('debug msg', msg);
      //return;
      this.error = `/: ${msg}`;
      await this.sleep(500);
      //console.log('awake');
    },
    async sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    },

    async login() {
      const provider = await this.getSamlProvider();
      const auth = getAuth();
      await signInWithRedirect(auth, provider, browserPopupRedirectResolver);
    },

    async getSamlProvider() {
      const eventId = this.$route.query.eventId;
      var providerId = this.$route.query.provider;

      if (eventId) {
        const event = (await eventService.get(eventId)).data;
        if (event && event.sso_config) {
          providerId = event.sso_config.firebase_provider;
        }
      }
      if (!providerId && tenant.ssoConfig) {
        providerId = tenant.ssoConfig.firebase_provider;
      }
      console.log('/// SAML provider:', providerId);

      if (!providerId) {
        return null;
      }

      return new SAMLAuthProvider(providerId);
    },

    async redirect() {
      //var response = await profileService.sso();
    },


  },
  computed: {
      isAppView() {
        return this.$route.query.view === 'app' || this.$store.state.view == 'app';
      },
      ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss" scoped>
  .fullscreen-bg {
    margin-top: 60px;
    padding-top: 40px;
    background-image: url(https://sodisp.imgix.net/hero-cycling-1.jpg?w=2000&h=2000&fit=crop);
    background-size: cover;
    background-position: 50%;

    .row { margin: 0!important; }
  }
  .view--embedded .fullscreen-bg { margin-top: 0;}
</style>

